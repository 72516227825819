<template>
  <sectionContainer>
    <div slot="title">Introduction</div>
    <div slot="content">
      <div class="columns">
        <figure class="column has-text-centered contain">
          <img v-lazy="'../img/cube.png'"></img>
        </figure>
        <figure class="column has-text-centered contain">
          <img v-lazy="'../img/final.png'"></img>
        </figure>
      </div>
      <p class="no-indent">
        In this page, we show impressive three-dimensional fractal objects
        called <i>quasi-fuchsian fractals</i> or <i>quasi-spheres</i>.
        They are made of tiling of a <i>sphairahedron</i>, a polyhedron with spherical faces.
        Sphairahedron is a coined word combining two words sphaira-
        (a prefix that means ’spherical’) and -hedron (a suffix that means ’polyhedron.’)
        It is introduced by Kazushi Ahara and Yoshiaki Araki in 2003 [1] to invent a
        new kind of fractal objects.
      </p>
      <div class="columns has-text-centered padding">
        <figure class="column">
          <img v-lazy="'../img/sphairaTessellation.gif'"></img><br>
          Tiling process of a finite sphairahedron
        </figure>
        <figure class="column">
          <img v-lazy="'../img/sphairaPrismTessellation.gif'"></img><br>
          Tiling process of an infinite sphairahedron
        </figure>
      </div>
      <p class="no-indent">
        We can make a tiling pattern of sphairahedra using reflections through their faces.
        In many cases, the boundary of the tiling converges to a three-dimensional
        fractal shape.
        The union of all the tiles is mostly homeomorphic to a three-dimensional ball.
        Thus this boundary is called a quasi-sphere.
        Mathematically speaking, we consider a Coxeter-like group generated by
        the inversions in all of the spherical faces of the sphairahedron.
        The boundary of the tiling is also called the limit set of the group.
        Also, under some technical conditions, the group is called a quasi-fuchsian group
        and the limit set a quasi-fuchsian fractal.
        For more details, see also [2] and [3].
      </p>
      <figure class="has-text-centered padding">
        <div class="movie-wrap">
          <iframe width="560" height="315"
                  src="https://www.youtube.com/embed/3lcO9zRCv-4?rel=0"
                  frameborder="0"
                  allow="autoplay; encrypted-media" allowfullscreen>
          </iframe>
         </div>
      </figure>
      <p class="no-indent">
        A quasi-fuchsian fractal is one of the three-dimensional fractals at an early
        era of visualizing fractals with a computer.
        The video posted by Ahara and Araki shows the fractal based on a cube type
        sphairahedron.
        Despite its interesting shapes, there are few publications and visualized images.
        However, There are more variations of sphairahedra and their fractals.
        We explored variations of sphairahedra and their fractals which Ahara and Araki
        did not visualize, and summarized them in our paper [4] and [5].
      </p>
    </div>
  </sectionContainer>
</template>

<script>
import SectionContainer from './sectionContainer.vue';

export default {
    components: {
        SectionContainer
    }
}
</script>

<style>
  .movie-wrap {
     position: relative;
     padding-bottom: 75%;
     height: 0;
     overflow: hidden;
}
 
.movie-wrap iframe {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}
</style>
