var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "h1",
      {
        staticClass: "title has-text-centered",
        attrs: { id: "titleWithoutSubtitle" }
      },
      [_vm._t("title")],
      2
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "container is-fluid" }, [_vm._t("content")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }