var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sectionContainer", [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Introduction")
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered contain" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/cube.png",
                expression: "'../img/cube.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered contain" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/final.png",
                expression: "'../img/final.png'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "no-indent" }, [
        _vm._v(
          "\n      In this page, we show impressive three-dimensional fractal objects\n      called "
        ),
        _c("i", [_vm._v("quasi-fuchsian fractals")]),
        _vm._v(" or "),
        _c("i", [_vm._v("quasi-spheres")]),
        _vm._v(".\n      They are made of tiling of a "),
        _c("i", [_vm._v("sphairahedron")]),
        _vm._v(
          ", a polyhedron with spherical faces.\n      Sphairahedron is a coined word combining two words sphaira-\n      (a prefix that means ’spherical’) and -hedron (a suffix that means ’polyhedron.’)\n      It is introduced by Kazushi Ahara and Yoshiaki Araki in 2003 [1] to invent a\n      new kind of fractal objects.\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns has-text-centered padding" }, [
        _c("figure", { staticClass: "column" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/sphairaTessellation.gif",
                expression: "'../img/sphairaTessellation.gif'"
              }
            ]
          }),
          _c("br"),
          _vm._v("\n        Tiling process of a finite sphairahedron\n      ")
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/sphairaPrismTessellation.gif",
                expression: "'../img/sphairaPrismTessellation.gif'"
              }
            ]
          }),
          _c("br"),
          _vm._v(
            "\n        Tiling process of an infinite sphairahedron\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "no-indent" }, [
        _vm._v(
          "\n      We can make a tiling pattern of sphairahedra using reflections through their faces.\n      In many cases, the boundary of the tiling converges to a three-dimensional\n      fractal shape.\n      The union of all the tiles is mostly homeomorphic to a three-dimensional ball.\n      Thus this boundary is called a quasi-sphere.\n      Mathematically speaking, we consider a Coxeter-like group generated by\n      the inversions in all of the spherical faces of the sphairahedron.\n      The boundary of the tiling is also called the limit set of the group.\n      Also, under some technical conditions, the group is called a quasi-fuchsian group\n      and the limit set a quasi-fuchsian fractal.\n      For more details, see also [2] and [3].\n    "
        )
      ]),
      _vm._v(" "),
      _c("figure", { staticClass: "has-text-centered padding" }, [
        _c("div", { staticClass: "movie-wrap" }, [
          _c("iframe", {
            attrs: {
              width: "560",
              height: "315",
              src: "https://www.youtube.com/embed/3lcO9zRCv-4?rel=0",
              frameborder: "0",
              allow: "autoplay; encrypted-media",
              allowfullscreen: ""
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "no-indent" }, [
        _vm._v(
          "\n      A quasi-fuchsian fractal is one of the three-dimensional fractals at an early\n      era of visualizing fractals with a computer.\n      The video posted by Ahara and Araki shows the fractal based on a cube type\n      sphairahedron.\n      Despite its interesting shapes, there are few publications and visualized images.\n      However, There are more variations of sphairahedra and their fractals.\n      We explored variations of sphairahedra and their fractals which Ahara and Araki\n      did not visualize, and summarized them in our paper [4] and [5].\n    "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }