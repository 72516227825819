var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sectionContainer", [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Fractal Renderer")
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
      _c("div", { staticClass: "has-text-centered" }, [
        _c("figure", { attrs: { id: "rendererImg" } }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/renderer.png",
                expression: "'../img/renderer.png'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "no-indent" }, [
        _vm._v(
          "\n      The first author is developing a web application to visualize sphairahedra and\n      their fractals interactively.\n      It is available at\n      "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://soma-arc.net/SphairahedronExperiment",
              target: "_blank"
            }
          },
          [_vm._v("\n        https://soma-arc.net/SphairahedronExperiment")]
        ),
        _vm._v(
          ".\n      The renderer requires WebGL 2.0 features. Also, according to the\n      rendering parameters, it may require high GPU power, thus please run it at your\n      own risk.\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Generally, in order to visualize these fractals with a computer,\n      we have to compute an infinite number of sphairahedra.\n      It often takes too much time.\n      However, there are algorithms to render three-dimensional fractals efficiently.\n      We use "
        ),
        _c("i", [_vm._v("sphere tracing")]),
        _vm._v(" and "),
        _c("i", [_vm._v("Iterated Inversion System (IIS)")]),
        _vm._v(
          ".\n      Sphere tracing is a kind of ray tracing techniques.\n      It is frequently used to render three-dimensional fractals.\n      For more details, read the "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "http://blog.hvidtfeldts.net/index.php/2011/06/distance-estimated-3d-fractals-part-i/",
              target: "_blank"
            }
          },
          [_vm._v("blog post")]
        ),
        _vm._v(
          " by Mikael H Christensen.\n      IIS is an algorithm to render fractals originated from reflections introduced\n      in our previous works [6].\n    "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }