var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sectionContainer", [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [_vm._v("Gallery")]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
      _c("h3", { staticClass: "title is-4" }, [_vm._v("Tetrahedron")]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/tetrahedron/prism.png",
                expression: "'../img/variations/tetrahedron/prism.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/tetrahedron/prismLimit.png",
                expression: "'../img/variations/tetrahedron/prismLimit.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/tetrahedron/sphairahedron.png",
                expression: "'../img/variations/tetrahedron/sphairahedron.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/tetrahedron/limit.png",
                expression: "'../img/variations/tetrahedron/limit.png'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "title is-4" }, [_vm._v("Pentahedral Pyramid")]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPyramid/prism.png",
                expression: "'../img/variations/pentahedralPyramid/prism.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPyramid/prismLimit.png",
                expression:
                  "'../img/variations/pentahedralPyramid/prismLimit.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPyramid/sphairahedron.png",
                expression:
                  "'../img/variations/pentahedralPyramid/sphairahedron.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPyramid/limit.png",
                expression: "'../img/variations/pentahedralPyramid/limit.png'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "title is-4" }, [_vm._v("Pentahedral Prism")]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPrism/flatPrism.png",
                expression: "'../img/variations/pentahedralPrism/flatPrism.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPrism/flat.png",
                expression: "'../img/variations/pentahedralPrism/flat.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value:
                  "../img/variations/pentahedralPrism/littleSpherePrism.png",
                expression:
                  "'../img/variations/pentahedralPrism/littleSpherePrism.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/pentahedralPrism/littleSphere.png",
                expression:
                  "'../img/variations/pentahedralPrism/littleSphere.png'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value:
                    "../img/variations/pentahedralPrism/prismAnimation.gif",
                  expression:
                    "'../img/variations/pentahedralPrism/prismAnimation.gif'"
                }
              ]
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value:
                    "../img/variations/pentahedralPrism/sphairahedronAnimation.gif",
                  expression:
                    "'../img/variations/pentahedralPrism/sphairahedronAnimation.gif'"
                }
              ]
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value:
                    "../img/variations/pentahedralPrism/pentahedralPrismType1.gif",
                  expression:
                    "'../img/variations/pentahedralPrism/pentahedralPrismType1.gif'"
                }
              ]
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value:
                    "../img/variations/pentahedralPrism/finitePentahedralPrismType1.gif",
                  expression:
                    "'../img/variations/pentahedralPrism/finitePentahedralPrismType1.gif'"
                }
              ]
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "title is-4" }, [_vm._v("Cube")]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/cube/prism0864.png",
                expression: "'../img/variations/cube/prism0864.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/cube/limit0864.jpg",
                expression: "'../img/variations/cube/limit0864.jpg'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/cube/prism-0864.png",
                expression: "'../img/variations/cube/prism-0864.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/cube/limit-0864.jpg",
                expression: "'../img/variations/cube/limit-0864.jpg'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "../img/variations/cube/cubeType1.gif",
                  expression: "'../img/variations/cube/cubeType1.gif'"
                }
              ]
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "../img/variations/cube/finiteCubeType1.gif",
                  expression: "'../img/variations/cube/finiteCubeType1.gif'"
                }
              ]
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "../img/variations/cube/cubeType3.gif",
                  expression: "'../img/variations/cube/cubeType3.gif'"
                }
              ]
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "../img/variations/cube/cubeType5.gif",
                  expression: "'../img/variations/cube/cubeType5.gif'"
                }
              ]
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "title is-4" }, [_vm._v("Hexahedral Cake")]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/hexahedralCake/prism.png",
                expression: "'../img/variations/hexahedralCake/prism.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/hexahedralCake/limit0644.jpg",
                expression: "'../img/variations/hexahedralCake/limit0644.jpg'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/hexahedralCake/sphairahedron.png",
                expression:
                  "'../img/variations/hexahedralCake/sphairahedron.png'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/variations/hexahedralCake/finiteLimit.png",
                expression: "'../img/variations/hexahedralCake/finiteLimit.png'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value:
                    "../img/variations/hexahedralCake/hexahedralCakeType0.gif",
                  expression:
                    "'../img/variations/hexahedralCake/hexahedralCakeType0.gif'"
                }
              ]
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value:
                    "../img/variations/hexahedralCake/finiteHexahedralCakeType0.gif",
                  expression:
                    "'../img/variations/hexahedralCake/finiteHexahedralCakeType0.gif'"
                }
              ]
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "../img/variations/hexahedralCake/cakeType1.gif",
                  expression: "'../img/variations/hexahedralCake/cakeType1.gif'"
                }
              ]
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns column" }, [
          _c("figure", { staticClass: "column has-text-centered" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "../img/variations/hexahedralCake/finiteCakeType1.gif",
                  expression:
                    "'../img/variations/hexahedralCake/finiteCakeType1.gif'"
                }
              ]
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }