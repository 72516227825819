var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "nav",
      {
        staticClass: "navbar is-dark",
        attrs: { role: "navigation", "aria-label": "main navigation" }
      },
      [
        _c("div", { staticClass: "navbar-brand" }, [
          _c("div", { staticClass: "navbar-start" }, [
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: { href: "https://sphairahedron.net" }
              },
              [_vm._v("\n        sphairahedron.net\n      ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: { href: "https://soma-arc.net/SphairahedronExperiment" }
              },
              [_vm._v("\n        SphairahedronRenderer\n      ")]
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }