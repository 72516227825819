<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <div class="navbar-start">
        <a class="navbar-item" href="https://sphairahedron.net">
          sphairahedron.net
        </a>
        <a class="navbar-item" href="https://soma-arc.net/SphairahedronExperiment">
          SphairahedronRenderer
        </a>
      </div>
  </div>
  </nav>
</template>

<script>
  export default {
  }
</script>
