var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-content"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "rootSection" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "h2",
            {
              staticClass: "subtitle is-8 has-text-centered",
              attrs: { id: "authors" }
            },
            [
              _vm._v(
                "\n      Kento Nakamura, Yoshiaki Araki, and Kazushi Ahara\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c("introContainer"),
          _vm._v(" "),
          _c("rendererContainer"),
          _vm._v(" "),
          _c("galleryContainer"),
          _vm._v(" "),
          _c("threeDPrintedContainer"),
          _vm._v(" "),
          _c("referenceContainer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      { staticClass: "title has-text-centered", attrs: { id: "" } },
      [
        _vm._v("\n      Polyhedron with Spherical Faces and"),
        _c("br"),
        _vm._v("\n      Four-Dimensional Kleinian Groups\n    ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }