var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sectionContainer", [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Three-dimensional Printed Fractals")
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
      _c("p", { staticClass: "no-indent" }, [
        _vm._v(
          "\n      In this section, we show 3D printed fractals.\n      You can download models at Sketchfab\n      ("
        ),
        _c(
          "a",
          {
            attrs: { href: "https://sketchfab.com/soma_arc", target: "_blank" }
          },
          [_vm._v("https://sketchfab.com/soma_arc")]
        ),
        _vm._v(
          ".)\n      In the first author's blog post, we show how to prepare the data for 3D printing\n      ("
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://soma.hatenablog.jp/entry/2017/12/24/003321",
              target: "_blank"
            }
          },
          [_vm._v("https://soma.hatenablog.jp/entry/2017/12/24/003321")]
        ),
        _vm._v(
          ".)\n      There are two types of printing materials.\n      They are Monochrome printing and full-colored printing.\n\n    "
        )
      ]),
      _c("br"),
      _vm._v(" "),
      _c("h3", { staticClass: "title is-4" }, [
        _vm._v("Monochrome printing by Makerbot Replicater Z18 with PLA resin.")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/1.JPG",
                expression: "'../img/3dPrinted/mono/1.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/2.JPG",
                expression: "'../img/3dPrinted/mono/2.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/3.JPG",
                expression: "'../img/3dPrinted/mono/3.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/4.JPG",
                expression: "'../img/3dPrinted/mono/4.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/5.JPG",
                expression: "'../img/3dPrinted/mono/5.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/6.JPG",
                expression: "'../img/3dPrinted/mono/6.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/7.JPG",
                expression: "'../img/3dPrinted/mono/7.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/mono/8.JPG",
                expression: "'../img/3dPrinted/mono/8.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "title is-4" }, [
        _vm._v("Full-colored printing by DMM.make 3D print.")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/1.JPG",
                expression: "'../img/3dPrinted/color/1.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/2.JPG",
                expression: "'../img/3dPrinted/color/2.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/3.JPG",
                expression: "'../img/3dPrinted/color/3.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/4.JPG",
                expression: "'../img/3dPrinted/color/4.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/5.JPG",
                expression: "'../img/3dPrinted/color/5.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/6.JPG",
                expression: "'../img/3dPrinted/color/6.JPG'"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/7.JPG",
                expression: "'../img/3dPrinted/color/7.JPG'"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("figure", { staticClass: "column has-text-centered" }, [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: "../img/3dPrinted/color/8.JPG",
                expression: "'../img/3dPrinted/color/8.JPG'"
              }
            ]
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }