var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sectionContainer", [
    _c("div", { attrs: { slot: "title" }, slot: "title" }, [
      _vm._v("Reference")
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
      _c("ul", [
        _c("li", { staticClass: "referenceList" }, [
          _vm._v(
            "\n          Kazushi Ahara and Yoshiaki Araki.\n          “Sphairahedral approach to parameterize visible three dimensional\n          quasi-Fuchsian fractals.”\n          Computer Graphics International Proceedings,\n          Tokyo, Japan, July, 9–11,\n          2003, pp. 226–229.\n          "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "http://ieeexplore.ieee.org/document/1214471/",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n            http://ieeexplore.ieee.org/document/1214471/"
              )
            ]
          ),
          _vm._v(".\n        ")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "referenceList" }, [
          _vm._v(
            '\n          Kazushi Ahara. "球面体と3次元擬フックス群."\n          数理解析研究所講究録, 1329巻, 2003年, pp. 109–114 (Japanese.)\n          '
          ),
          _c(
            "a",
            {
              attrs: {
                href:
                  "http://www.kurims.kyoto-u.ac.jp/~kyodo/kokyuroku/contents/pdf/1329-14.pdf",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n            http://www.kurims.kyoto-u.ac.jp/~kyodo/kokyuroku/contents/pdf/1329-14.pdf"
              )
            ]
          ),
          _vm._v(".\n        ")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "referenceList" }, [
          _vm._v(
            '\n          Kazushi Ahara. "quasisphere" Ahara lab. Meiji University.\n          '
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://www51.atwiki.jp/kazushiahara/pages/28.html",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n            https://www51.atwiki.jp/kazushiahara/pages/28.html"
              )
            ]
          ),
          _vm._v(".\n        ")
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "referenceList" }, [
          _vm._v(
            'Kento Nakamura and Kazushi Ahara.\n          "Sphairahedra and Three-Dimensional Fractals." Bridges Conference Proceedings, Stockholm, Sweden, July, 25–29, 2018,\n          pp. 171–178.\n\t\t  '
          ),
          _c(
            "a",
            {
              attrs: {
                href:
                  "http://archive.bridgesmathart.org/2018/bridges2018-171.html",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n            http://archive.bridgesmathart.org/2018/bridges2018-171.html"
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "referenceList" }, [
          _vm._v(
            "Kento Nakamura, Yoshiaki Araki, and Kazushi Ahara.\n          “Polyhedron with Spherical Faces and Four-Dimensional Kleinian Groups.” \n          Preprint.\n          "
          ),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://github.com/soma-arc/ExperimentalMathematics/releases/tag/1.0",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n            https://github.com/soma-arc/ExperimentalMathematics/releases/tag/1.0\n          "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "referenceList" }, [
          _vm._v(
            "Kento Nakamura and Kazushi Ahara. “A Geometrical Representation and Visualization of Möbius\n          Transformation Groups.” Bridges Conference Proceedings, Waterloo, Canada, July, 27–31, 2017,\n          pp. 159–166.\n          "
          ),
          _c(
            "a",
            {
              attrs: {
                href:
                  "http://archive.bridgesmathart.org/2017/bridges2017-159.html",
                target: "_blank"
              }
            },
            [
              _vm._v(
                "\n            http://archive.bridgesmathart.org/2017/bridges2017-159.html"
              )
            ]
          ),
          _vm._v(".\n        ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }